<template>
    <div class="following-list">
        <memod-list :list-endpoint="listEndpoint" :no-memos-found-message="noMemosFoundMessage" />
    </div>
</template>

<script>
export default {
    name: "FollowingList",
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/avatar.svg"),
                title: "Sorry, no results",
                text: "Nothing here...yet! Explore your Home feed and follow Creators to see their Memos here"
            }
        }
    }
}
</script>
