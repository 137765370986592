<template>
    <div class="web-app-topics">
        <side-bar v-if="!isLoggedIn" />

        <div class="container main-content">
            <div class="row no-gutters topic-view-header">
                <div class="col-auto topic-back">
                    <back-button />
                </div>
                <div class="col topic-title-and-memos-total">
                    <h1>{{ title }}</h1>
                    <h2 v-if="showSubTitle" class="sub-title">
                        {{ subTitle }}
                    </h2>
                </div>
            </div>
            <memod-list
                v-if="type === 'Memos'"
                :list-endpoint="listEndpoint"
                :pagination-format="false"
                use-api-v2
            />
            <div
                v-else
                v-infinite-scroll="fetchWidgetContent"
                infinite-scroll-immediate-check="false"
                infinite-scroll-disabled="fetchingList"
                infinite-scroll-distance="400"
                class="boards-list"
            >
                <div
                    v-masonry="masonryListName"
                    transition-duration=".3s"
                    item-selector=".item"
                    :gutter="30"
                    :fit-width="true"
                    :origin-top="false"
                    :destroy-delay="2000"
                >
                    <template v-for="entity in entityItems">
                        <board-card
                            v-if="type === 'Boards'"
                            :key="entity.id"
                            v-masonry-tile
                            class="item"
                            :board="entity"
                            :is-notable="true"
                            :container-style="{ 'margin-bottom': '24px' }"
                        />
                        <topic-card
                            v-if="type === 'Topics'"
                            :key="entity.id"
                            v-masonry-tile
                            class="item"
                            :topic="entity"
                            :container-style="{ width: '400px', 'margin-bottom': '16px' }"
                            @toggled-follow="(is_following) => entity.is_follow = is_following"
                        />
                        <people-card
                            v-if="type === 'Users'"
                            :key="entity.id"
                            v-masonry-tile
                            :user="entity"
                            class="item"
                            :show-description="true"
                            :container-style="{ width: '400px' }"
                            @toggled-follow="(is_following) => entity.is_following = is_following"
                        />
                    </template>
                </div>
            </div>
            <fullscreen-loader v-if="fetchingList" />
        </div>
    </div>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import { mapGetters } from "vuex";

export default {
    name: "NotableDetails",
    components: {
        BoardCard: () => import(/* webpackChunkName: "board-card" */ "@/components/molecules/board-card.vue"),
        BackButton: () => import(/* webpackChunkname: "back-button" */ "@c/atoms/back-button"),
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar"),
        TopicCard: () => import(/* webpackChunkName: "topic-card" */ "@/components/molecules/topic-card/"),
        PeopleCard: () => import(/* webpackChunkName: "people-card" */ "@c/molecules/people-card"),
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue")
    },
    directives: {
        infiniteScroll
    },
    data() {
        return {
            entityItems: [],
            noDataLeft: false,
            fetchingList: false,
            firstAttemptToFetchData: false,
            pagination: {
                limit: 25,
                page: null,
                total_pages: 1
            },
            type: this.$route.params.type,
            title: this.$route.params.title,
            subTitle: this.$route.params.subtitle
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        uuid() {
            return this.$route.params.uuid;
        },
        listEndpoint() {
            return `/notable-widget/${this.uuid}/entities?format=true`;
        },
        masonryListName() {
            return `${this.$route.name}`;
        },
        showSubTitle() {
            return (this.type === "Topics" || this.type === "Users") && this.subTitle && this.subTitle.length
        }
    },
    created() {
        if (!this.type || !this.title) {
            this.fetchWidgetInfo();
        } else if (this.type !== "Memos") {
            this.fetchWidgetContent();
        }
    },
    methods: {
        fetchWidgetInfo() {
            this.fetchingList = true;
            axiosV2.get(`notable-widget/${this.uuid}?light=true`).then(({ data }) => {
                this.type = data.category.name;
                this.title = data.name;
            }).catch((error) => {
                console.log(error.response.data.message);
                this.fetchingList = false;
            }).finally(() => {
                this.fetchWidgetContent();
            })
        },
        fetchWidgetContent() {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            if (this.noDataLeft) {
                return;
            }

            const limit = this.pagination.limit;
            const nextPage = this.pagination.page ? (this.pagination.page + 1) : 1;

            if (Number(nextPage) <= this.pagination.total_pages) {
                this.fetchingList = true;

                axiosV2.get(`/notable-widget/${this.uuid}/entities?format=true&limit=${limit}&page=${nextPage}`).then((response) => {
                    // This is temporary needed due to an inconsistency in the backend pagination,
                    // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
                    // backend after there's no memods left in the data array that is being returned by the backend
                    // eslint-disable-next-line no-prototype-builtins
                    if (response.data.hasOwnProperty("data") && !response.data.data.length) {
                        this.noDataLeft = true;
                        return;
                    }

                    const entityItems = response.data.data ? response.data.data : response.data.results;
                    const page = response.data.page ? response.data.page : response.data.meta.page.current;
                    const totalPages = response.data.total_pages ? response.data.total_pages : response.data?.meta?.page?.total_pages;
                    const limit = response.data.limit ? response.data.limit : response.data.meta.page.limit;

                    for (var i = 0; i < entityItems.length; i++) {
                        this.entityItems.push({ ...entityItems[i] });
                    }

                    this.pagination.page = page;
                    this.pagination.total_pages = totalPages;
                    this.pagination.limit = limit;

                }).finally(() => {

                    if (!this.firstAttemptToFetchData) {
                        setTimeout(() => {
                            this.$redrawVueMasonry(this.masonryListName);
                        }, 300);
                    } else {
                        this.$redrawVueMasonry(this.masonryListName);
                    }

                    if (!this.firstAttemptToFetchData) {
                        this.firstAttemptToFetchData = true;
                    }
                    this.fetchingList = false;
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-topics {
    .main-content {
        padding-top: 100px;
        margin-bottom: 120px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .back-button {
            margin-right: 40px;
        }

        .topic-view-header {
            margin-bottom: 40px;

            .topic-title-and-memos-total {

                h1 {
                    padding-top: 7px;
                    margin-bottom: 6px;
                }

                .total-memos {
                    @extend .body-text;
                    display: flex;
                    align-items: baseline;
                    line-height: 14px;
                    color: rgba(235, 235, 245, 0.6);
                    margin-right: 10px;

                    strong {
                        color: white;
                        font-weight: 600;
                        display: block;
                        margin-right: 5px;
                    }
                }
            }
        }

        @media(max-width: $md) {
            padding-top: 20px;

            .topic-view-header {
                position: relative;

                .topic-back {
                    order: 1;
                    flex-basis: 100%;
                }

                .topic-title-and-memos-total {
                    order: 3;

                    h1 {
                        text-align: center;
                    }

                    .total-memos {
                        justify-content: center;
                    }
                }

                .topic-options {
                    order: 2;
                    flex: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }

        .loader {
            position: relative;
            background-color: transparent;
            height: 200px;

            /deep/ img {
                width: 100px !important;
            }
        }
    }
}
</style>
